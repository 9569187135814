.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.right-nav{
  justify-content: right;
}
.small-text {
  font-size: 12px; 
}

.input-label {
  font-size: 1rem;
  padding: 5px;
}
.tableCheckHeader{
  padding: 5px;
}
.checkbox-label {
  font-size: 1rem;
  padding-left: 5px;
}
.input-box {
  padding: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
  background-color: lightgray;
  align-items: center;
}
.email-input-box {
  padding: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
  background-color: lightgray;
  align-items: center;
  width: 400px;
}
.search-box {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  margin-top: 1rem;
  background-color: lightgray;
  margin-bottom: 10px;
}

.submit-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 10px 28px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 5px;
}

.submit-button:hover{
  border: 2px solid green;
  background-color: white; 
}
.submit-button:active {
  transform: translateY(4px);
}
.cancel-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 10px 28px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 5px;
}

.cancel-button:hover{
  border: 2px solid red;
  background-color: white; 
}
.cancel-button:active {
  transform: translateY(4px);
}
.delete-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 10px 28px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 5px;
}

.delete-button:hover{
  border: 2px solid black;
  background-color: red; 
}
.delete-button:active {
  transform: translateY(4px);
}
.home-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 10px 28px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 20px;
  
}

.home-button:hover{
  border: 2px solid rgb(93, 181, 222);
  background-color: white; 
}
.home-button:active {
  transform: translateY(4px);
}
.logout-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 10px 28px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 20px;
}

.logout-button:hover{
  border: 2px solid red;
  background-color: white; 
}
.logout-button:active {
  transform: translateY(4px);
}
.add-child-button:active {
  transform: translateY(4px);
}

.add-child-button{
  background-color: white; 
  color: #000000;
  border: 2px solid green;
  padding: 5px 10px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.add-child-button:hover{
  border: 2px solid green;
  background-color: gray; 
  color: white;
}

.language-button:active {
  transform: translateY(4px);
}

.language-button{
  position: fixed;
  top: 10px;
  left: 20px;
  background-color: white; 
  color: #000000;
  border: 2px solid rgb(93, 181, 222);
  padding: 5px 10px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.language-button:hover{
  border: 2px solid rgb(93, 181, 222);
  background-color: rgb(93, 181, 222); 
  color: white;
}
.remove-child-button:active {
  transform: translateY(4px);
}
.remove-child-button{
  background-color: white; 
  color: #000000;
  border: 2px solid red;
  padding: 5px 10px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

}

.remove-child-button:hover{
  border: 2px solid red;
  background-color: gray; 
  color: white;
}

.Income{
  display: flex;
  justify-content: center;
  align-items: center;
}
.table2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header{
  margin-top: -1rem;
}
.required-indicator{
  color: darkred;
}

.HomePageButton {
  color: black;
  background-color: rgb(93, 181, 222);
  font-size: 30px;
  font-weight: 20px;
  padding: 15px;
  width: 20%;
  border: 2px;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  /*table-layout: fixed;*/
  /*border-collapse: collapse;*/
  
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.HomePageButton:hover {
  color: white;
  transform: translateY(-5px);
}
.result-box {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  margin: auto;
  background-color: lightgray;
  width: max-content;
  height: fit-content;
}
.results-container {
  text-align: center;
}
.leftHeader{
  justify-content: left;
}
.rank-box {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  border: none;
  background-color: lightgray;
  width: 50px;
  height: fit-content;
  margin-left: 5px;
}
.pdf-button:active {
  transform: translateY(4px);
}

.pdf-button{
  background-color: white; 
  color: #000000;
  border: 2px solid black;
  padding: 5px 10px;
  text-align: center;
  text-decoration-color: #000000;
  display: inline-block;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 10px;
}

.pdf-button:hover{
  border: 2px solid black;
  background-color: gray; 
  color: white;
}
.children-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.child-field {
  margin-bottom: 5px;
  
}

.child-info {
  display: flex;
  flex-direction: column;
}
.children-display-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.child-display-field {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  margin-left: 5px;
}

.child-display-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.office-use {
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.rank-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 5px;
}

.office-notes-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 5px;
}

.stack {
  display: flex;
  flex-direction: column;
}
/* CSS for select dropdowns */
select {
  font-family: Arial, sans-serif;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 16px;
}



/* Optional: Style for the disabled state */
select:disabled {
  background-color: #f0f0f0;
  color: #888;
}

/* Optional: Add some margin to separate the dropdown from the input box */
.input-box + select {
  margin-left: 8px;
}

.expandable-input {
  width: auto;
  min-width: 100px; /* Set a reasonable minimum width */
  max-width: 100%; /* Ensure the input doesn't exceed its container */
}

/* Base styles for mobile devices */
.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; /* Add spacing between input fields */
}
.group2{
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
}
.grid {
  text-align: center;
  vertical-align: middle;
}
.table-element{
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* Media query for mobile devices (e.g., smartphones) */
@media (max-width: 767px) {
  .Form {
    /* Reset flex-direction and alignment */
    flex-direction: column;
    align-items: center;

    /* Add padding to the form to prevent cutoff */
    padding: 1rem; /* Adjust as needed */
  }
  

  /* Adjust individual input styles as needed */
  .Form label {
    width: 100%; /* Full width for labels on mobile */
    margin-bottom: 0.5rem; /* Add spacing between labels */
    text-align: left; /* Align labels to the left */
  }

  .Form input {
    width: 100%; /* Full width for input fields on mobile */
  }

 /* Media query for mobile devices (e.g., smartphones) */
  .grid {
    display: block; /* Stack columns vertically */
    text-align: center;
  }

  .grid thead,
  .grid tbody {
    display: block; /* Display table parts as block */
    
  }

  .grid tr {
    display: flex; /* Use flex layout for rows */
    flex-direction: column; /* Stack cells vertically */
    margin-bottom: 1rem; /* Add spacing between rows */
  }

  .grid th,
  .grid td {
    width: 100%; /* Full width for table cells on mobile */
    text-align: left; /* Align text to the left */
  }
  .table-element{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .group2{
    align-items: center;
    text-align: center;
    display: block;
    flex-direction: column;
    width: 100%;
  }
 


}
  





